import * as Yup from 'yup';

const requiredField = 'Champ requis';

const requiredString = Yup.string().required(requiredField);

const contactSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required(requiredField),
  comment: requiredString,
});

export { contactSchema };

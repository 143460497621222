import React from 'react';
import { IcFb, IcIg } from '../assets';
import { Box, Image } from '@chakra-ui/react';

export default function SocialNetworks({ size }) {
  console.log(Number(size));
  return (
    <Box display="flex" flexDir="row" h={size} justifyContent="center">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/caniconnectvosges/"
        aria-label="Lien Facebook"
      >
        <Image
          src={IcFb}
          w={size}
          h={size}
          p="12px"
          cursor="pointer"
          transition="transform .1s"
          _hover={{
            transform: 'scale(1.5)',
          }}
          alt="Logo de Facebook"
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/cani_connect/"
        aria-label="Lien Instagram"
      >
        <Image
          src={IcIg}
          w={size}
          h={size}
          p="12px"
          cursor="pointer"
          transition="transform .1s"
          _hover={{
            transform: 'scale(1.5)',
          }}
          alt="Logo de Instagram"
        />
      </a>
    </Box>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Button,
  Textarea,
  Text,
  Heading,
} from '@chakra-ui/react';
import Container from '../components/Container';
import { Formik } from 'formik';
import { FirebaseContext } from '../FirebaseContext';
import { contactSchema } from '../schemas';
import ReCAPTCHA from 'react-google-recaptcha';
import { headers } from '../headers';
import Header from '../components/Header';
import SocialNetworks from '../components/SocialNetworks';

function Contact() {
  const { sendEmail } = useContext(FirebaseContext);
  const [showValidation, setShowValidation] = useState(null);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const recaptchaRef = React.createRef();
  const descriptionSecondPart =
    'ou via le formulaire de contact\n👇 ci-dessous 👇';

  useEffect(() => {
    if (!isCaptchaValid) {
      recaptchaRef.current?.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaValid]);

  return (
    <Container>
      <Flex
        direction="column"
        flex={1}
        justifyContent="center"
        w="100%"
        gap={4}
      >
        <Flex direction="column" fontSize="2xl" alignItems="center" gap={4}>
          <Heading as="h1">Pour me contacter :</Heading>
          <Flex
            direction="row"
            w={{
              base: '300px',
              xl: '700px',
            }}
            flexWrap="wrap"
            gap={4}
          >
            <Flex
              direction="column"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Flex direction="column" gap={4}>
                <Flex whiteSpace="pre-wrap">
                  ☎️{'  '}
                  <a href="tel:0656666906">
                    <Text textDecor="underline">06.56.66.69.06</Text>
                  </a>
                </Flex>
                <Flex whiteSpace="pre-wrap">
                  📧{'  '}
                  <a href="mailto:contact@caniconnect.fr">
                    <Text textDecor="underline">contact@caniconnect.fr</Text>
                  </a>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction="column"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <SocialNetworks size="72px" />
            </Flex>
          </Flex>
        </Flex>
        <Formik
          initialValues={{ email: '', comment: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            const result = await sendEmail(values.email, values.comment);
            setShowValidation(result ? true : false);
            if (result) {
              setTimeout(() => {
                setShowValidation(null);
              }, 5000);
            }
            setSubmitting(false);
            setIsCaptchaValid(false);
          }}
          validationSchema={contactSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Flex justifyContent="center">
                <Flex
                  display="flex"
                  direction="column"
                  gap={2}
                  bg="white"
                  padding={5}
                  borderRadius={'xl'}
                  justifyContent="center"
                  alignItems="center"
                  w={{
                    base: '300px',
                    xl: '700px',
                  }}
                >
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    backgroundColor="#f1f1f1"
                    _placeholder={{ color: 'gray.800' }}
                    placeholder="Votre email"
                  />
                  <Box alignSelf="flex-start">
                    {errors.email && touched.email && errors.email}
                  </Box>
                  <Textarea
                    name="comment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comment}
                    backgroundColor="#f1f1f1"
                    _placeholder={{ color: 'gray.800' }}
                    placeholder="Votre commentaire"
                    h={{
                      base: '100px',
                      xl: '200px',
                    }}
                  />
                  <Box alignSelf="flex-start">
                    {errors.comment && touched.comment && errors.comment}
                  </Box>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lfo3XIhAAAAAL1SHLtjUvBUQx94lcLkqk7CSsjS"
                    onChange={value => {
                      if (value) {
                        setIsCaptchaValid(true);
                      }
                    }}
                    size={
                      typeof window !== 'undefined' &&
                      window.screen.width >= 1280
                        ? 'normal'
                        : 'compact'
                    }
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isCaptchaValid}
                    colorScheme="test"
                    w="100%"
                  >
                    Envoyer
                  </Button>
                  {showValidation && <Box>Message envoyé !</Box>}
                  {showValidation === false && (
                    <Box>Erreur lors de l'envoi du mail.</Box>
                  )}
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </Container>
  );
}

export default Contact;

export function Head() {
  return <Header {...headers.contact} />;
}
